* {
    position: relative;
    letter-spacing: 1px;
    font-weight: 200;
    color: $color-gray;
    font-size: 12px;
    font-family: 'Julius Sans One', 'sans-serif';
    // border: 1px solid black;
};

html,body{
    background-color: $color-yellow-light;
};

.navbar-toggler {
    border: none;
}

.overflow-hidden {
    overflow: hidden;
}

header::before {
    content: '';
    display: block;
    width: 100%;
    height: 200px;
}

.navbar-nav-left ul {
    width: 100%;
    display: flex;
    justify-content: space-between;
    list-style: none;
    padding: 0;
}

// 調整 navbar logo 大小
.navbar-brand {
    .navbar-brand-img-center {
        width: 200px;
        transition: 1s;
    };
    .navbar-brand-img-left {
        width: 60px;
    };
};

// navbar 背景顏色改成淡黃色
.bg-yellow {
    background-color: $color-yellow-light;
};

// 加入花花

.bgFlowerS {
    width: 36%;
    position: absolute;
    z-index: 1;
    right: -18%;
    top: 24%;
    transform: rotate(20deg);
}

.bgFlowerL {
    width: 50%;
    position: absolute;
    //z-index: 1;
    left: -28%;
    top: -20%;
    transform: rotate(8deg);
}

.card-title {
    color: $color-dark-gray;
    letter-spacing: 1.1px;
};

.home-gallery {
    width: 100%;
    position: relative;
    background-color: $color-green;
    .card {
        border: none;
    };
};

.home-talk {
    padding-top: 80px;
    padding-bottom: 80px;
    p {
        margin: 0;
    }
}

.z-index-1{
    z-index: 3;
};

.btn-success {
    background-color: $color-dark-green;
    border-color: $color-dark-green;
};

.main-content {
    // 為了不讓 navbar 擋住
    // position: relative;
    // background-image: url("../asset/bg/flower_l.svg"), url('../asset/bg/flower_s.svg');
    // background-repeat: no-repeat;
    // background-attachment: scroll;
    // background-color: $color-yellow-light;
    // background-size: 650px, 1400px;
    // background-position: left -300px bottom -500px, right -700px top -130px;
};

.card-home-service {
    background-color: $color-green;
    border: none;
    &:hover {
        background-color: $color-white;
        // border: $color-green 1px solid;
        .card-title {
            color: $color-green;
            font-weight: 400;
        }
    }
};

.card-img-body {
    // 想讓 height 跟著 width 改變而改變的維持比例一致
    position: relative;
    width: 100%;
    padding-top: 80%;


    img {
        // 比例不變的去填滿自己的大小
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        object-fit: cover;
        object-position: center;
    };
};

.socialMedia {
    list-style: none;
    padding: 0;
    display: flex;
    
    li {
        .socialMediaImg {
            width: 20px;
        };
    };
}

.footerContactUsList {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 36px;
}
.footerContactUsItem {
    margin-bottom: 16px;
}
.footerContactUsItem:nth-child(odd) {
    width: 50%;
}
a {
    color: $color-gray;
}
a:hover {
    color: $color-dark-green;
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) { 
    .card-img-body {
        width: 100%;
        padding-top: 60%;
    };
    header::before {
        height: 80px;
    };
    .bgFlowerS {
        width: 36%;
        position: absolute;
        z-index: 1;
        right: -18%;
        top: 16%;
        transform: rotate(20deg);
    }
    .footerContactUsItem:nth-child(1) {
        z-index: 2;
        position: absolute;
        right: 0;
        display: flex;
        justify-content: flex-end;
    }
    .footerContactUsItem {
        width: 100%;
    }
};

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) { 
    .card-img-body {
        width: 100%;
        padding-top: 60%;
    };
    .bgFlowerS {
        width: 40%;
        position: absolute;
        z-index: 1;
        right: -20%;
        top: 5%;
    }
    .bgFlowerL {
        display: none;
    }
    .navbar-brand-img-left {
        width: 64px;
    };

    .footerContactUsList {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 24px 0px 0px 0px;
    }
    .footerContactUsItem {
        margin-bottom: 12px;
    }
};

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {

};

// Large devices (desktops, less than 1200px)
@media (min-width: 1199.98px) {

};