.card-gallery {
    background-color: rgba(#fff,0);
    border: none;
};

.gallery-form-checkBoxes {
    padding: 10px 0 0 0;
    background-color: $color-yellow;
};

.checkbox-category-title {
    font-weight: 500;
};

.form-check, .form-check-input, .form-check-label {
    cursor: pointer;
};

a:hover {
    text-decoration: none;
    p {
        font-weight: bolder;
    }
}

@media (max-width: 991.98px) { 

};