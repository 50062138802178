.keep-ratio-1-to-1 {
    // 想讓 height 跟著 width 改變而改變的維持比例一致
    position: relative;
    width: 25%;
    padding-top: 25%;


    img {
        // 比例不變的去填滿自己的大小
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        object-fit: cover;
        object-position: center;
    };
};

.feedbackList {
    column-count: 2;
    // column-fill: auto;
    column-gap: 16px;
}
.feedbackItem {
    display: block;
    margin-bottom: 16px;
    text-align: center;
    break-inside: avoid-column;
    a {
        display: block;
        background-color: $color-white;
        padding: 28px;
    }

}
.feedbackItemFbIcon {
    font-size: 88px;
    margin-bottom: 24px;
    color: $color-dark-green;
}
.feedbackItemTitle {
    font-size: 16px;
    margin-bottom: 24px;
}
.feedbackItemText {
    font-size: 12px;
    line-height: 32px;
}
@media (max-width: 991.98px) { 
    .feedbackList {
        // column-fill: auto;
        column-gap: 12px;
    }
    .feedbackItem {
        display: block;
        margin-bottom: 12px;
        text-align: center;
        break-inside: avoid-column;
    }
    .feedbackItemFbIcon {
        font-size: 80px;
        margin-bottom: 20px;
        color: $color-dark-green;
    }
    .feedbackItemTitle {
        font-size: 14px;
        line-height: 28px;
        margin-bottom: 20px;
    }
    .feedbackItemText {
        font-size: 12px;
        line-height: 28px;
    }
};

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
    .feedbackList {
        column-count: 1;
        // column-fill: auto;
        column-gap: 12px;
    }
    .feedbackItem {
        a {
            padding: 24px;
        }
    }
    .feedbackItemFbIcon {
        font-size: 80px;
        margin-bottom: 20px;
        color: $color-dark-green;
    }
    .feedbackItemTitle {
        font-size: 14px;
        line-height: 28px;
        margin-bottom: 20px;
    }
    .feedbackItemText {
        font-size: 12px;
        line-height: 28px;
    }
};