.letter-spacing-5px {
    letter-spacing: 5px;
}

.work-card-img-body-main {
    padding-top: 60%;
}

// .work-card-img-body {
//     // height: 300px;
// }

ul {
    list-style: none;
    padding: 0;
}

.water-fall-body {
    column-count: 2;

}
.water-fall-item {
    margin-bottom: 16px;
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) { 
    .water-fall-body {
        column-count: 1;
    
    }
};
