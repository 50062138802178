.object-position-50-80{
    object-position: 50% 80%
};

.main-content-service {
    // 為了不讓 navbar 擋住
    padding-top: 200px;
    position: relative;
    background-image: url('../asset/bg/flower_s.svg');
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-size: 1800px;
    background-position: right -860px top -730px;
};
.serviceList {
    li:nth-child(even){
        flex-direction: row-reverse;
    }
}
.serviceItem {
    .serviceImgBody {
        width: 40%;
        padding-bottom: 30%;
        img {
            width: 100%;
            height: 100%;
            position: absolute;
            object-fit: cover;
        }
    }
    .serviceTextBody {
        width: 60%;
        background-color: $color-white;
        padding: 36px 64px 36px 64px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        p {
            line-height: 24px;
            font-family: 'Roboto';
            font-weight: 300;
        }
    }
    h2 {
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 16px;
    }
    p {
        line-height: 24px;
    }
    .serviceBookingBtn {
        align-self: flex-end;
        border-radius: 0;
        padding: 8px 12px;
        &:hover {
            background-color: transparent;
            color: $color-green;
            border: 1px solid $color-green;
        }
    }
    
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) { 
    .serviceItem {
        flex-direction: column;
        align-items: center;
        .serviceImgBody, .serviceTextBody {
            width: 64%;
        }
        .serviceImgBody {
            padding-bottom: 48%;
        }
        .serviceTextBody {
            padding: 24px 64px 24px 64px;
        }
        .serviceBookingBtn {
            align-self: center;
            border-radius: 0;
            padding: 8px 12px;
        }
    }
    .serviceList {
        li:nth-child(even){
            flex-direction: column;
        }
    }


    

};

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
    .serviceItem {
        flex-direction: column;
        align-items: center;
        .serviceImgBody, .serviceTextBody {
            width: 100%;
        }
        .serviceImgBody {
            padding-bottom: 75%;
        }
        .serviceBookingBtn {
            align-self: center;
            border-radius: 0;
            padding: 8px 12px;
            width: 100%;
        }
    }

};

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {

};

// Large devices (desktops, less than 1200px)
@media (min-width: 1199.98px) {

};