// vedio 比例調為 5 比 2
.img-body-5-to-2 {
    position: relative;
    width: 100%;
    padding-bottom: 40%;
}
.img-body-5-to-3 {
    position: relative;
    width: 100%;
    padding-bottom: 60%;
}
.img-body-5-to-2point25 {
    position: relative;
    width: 100%;
    padding-bottom: 45%;
}
.img-item-cover-center {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.img-body-4-to-3 {
    position: relative;
    width: 100%;
    padding-bottom: 75%;
}