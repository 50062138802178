.blogImgBody {
    width: 40%;
    padding-bottom: 28%;
    position: relative;
    img {
        width: 100%;
        height: 100%;
        position: absolute;
        object-fit: cover;
    }
}

.blogTextBody {
    width: 60%;
    background-color: $color-white;
    padding: 32px 80px 32px 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h2 {
        font-size: 12px;
        font-weight: 300;
        margin-bottom: 16px;
    }
    p {
        line-height: 24px;
        margin-bottom: 32px;
    }
}

.blogBookingBtn {
    border-radius: 0;
    padding: 8px 12px;
    &:hover {
        background-color: transparent;
        color: $color-green;
        border: 1px solid $color-green;
    }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) { 
    .blogItem {
        flex-direction: column;
        align-items: center;
    }
    .blogImgBody, .blogTextBody {
        width: 80%;
    }
    .blogImgBody {
        padding-bottom: 48%;
    }
    .blogTextBody {
        padding: 32px 48px 32px 48px;
        p {
            margin-bottom: 24px;
        }
    }

    .blogBookingBtn {
        align-self: center;
        border-radius: 0;
        padding: 8px 12px;
    }
    .blogList {
        li:nth-child(even){
            flex-direction: column;
        }
    }
};

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
    .blogImgBody, .blogTextBody {
        width: 100%;
    }

    .blogBookingBtn {
        align-self: center;
        border-radius: 0;
        padding: 8px 12px;
        width: 100%;
    }

};

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {

};

// Large devices (desktops, less than 1200px)
@media (min-width: 1199.98px) {

};