textarea {
    resize: none;
}
input, textarea {
    font-family: serif, "Microsoft Yahei";
}

// .main-content-survey {
//     position: relative;
//     background-image: url('../images/bg/flower_s.svg');
//     background-repeat: no-repeat;
//     background-attachment: scroll;
//     background-size: 600px;
//     background-position: right -100px top 0px;
// };

.survey-title {
    p {
        font-size: 14px;
    };
};

.survyList {
    // li {
    //     display: flex;
    //     align-items: center;
    // }
    label {
        width: 100%;
    }
}
.form-control, .custom-select {
    border: none;
    border-radius: 0;
    &:hover {
        border: $color-green 1px solid;
    }
}