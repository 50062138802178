.main-content-aboutUs {
    overflow: hidden;
    padding-bottom: 80px;
    position: relative;
    // background-image: url('../images/bg/flower_s.svg');
    // background-repeat: no-repeat;
    // background-attachment: scroll;
    // background-color: $color-yellow-light;
    // background-size: 1400px;
    // background-position: right -700px top -330px;
};

.aboutUsBgFlowerS {
    width: 36%;
    position: absolute;
    z-index: 1;
    right: -18%;
    top: 20%;
    transform: rotate(20deg);
}

.aboutUsImgBody {
    width: 40%;
    position: relative;
    .aboutUsMainImg {
        position: absolute;
        width: 75%;
        right: 0;
    }
    .aboutUsRingImg {
        width: 50%;
        position: absolute;
        bottom: 0;
        left: 0;
    }
}

.aboutUsTextBody {
    width: 40%;
    white-space: pre-wrap;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    p, h5 {
        display: block;
        font-size: 12px;
        line-height: 24px;
    }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) { 
    .aboutUsContainer {
        flex-direction: column;
    }
    .aboutUsImgBody {
        width: 100%;
        padding-bottom: 60%;
        overflow: hidden;
        .aboutUsMainImg {
            width: 100%;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
        .aboutUsRingImg {
            display: none;
        }
    }
    
    .aboutUsTextBody {
        width: 100%;
        white-space: pre-wrap;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 40px auto 0px auto;
        p, h5 {
            display: block;
            font-size: 12px;
            line-height: 24px;
        }
    }
};

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) { 

};

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {

};

// Large devices (desktops, less than 1200px)
@media (min-width: 1199.98px) {

};